<template>
  <AppLayout>
    <template v-slot:appContent>
      <div>
        <div class="d-sm-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
          <h4 class="">Warehouses/Create</h4>
          <div>
            <router-link :to="{...previousRoute}">
              <span class="glow d-flex align-items-center"><i class='bx bx-undo'></i>&nbsp;Back</span>
            </router-link>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <!-- for large screen -->
            <div class="d-none d-md-block">
              <div class="row">
                <div class="col-md-3 text-center  ">
                  <p class="py-1 my-0 " :class="[tabStep === 1 ? 'active-form' : 'inactive-form']">Address
                    Information</p>
                </div>
                <div class="col-md-3 text-center ">
                  <p class="py-1 my-0" :class="[tabStep === 2 ? 'active-form' : 'inactive-form']">Warehouse
                    Information</p>
                </div>
              </div>
            </div>
            <!-- for small screen  -->
            <div class="d-block d-md-none">
              <div class="row">
                <div v-if="tabStep === 1" class="col-md-10 text-center  ">
                  <p class="py-1 my-0 active-form">Address Information</p>
                </div>
                <div v-else-if="tabStep === 2 " class="col-md-10 text-center ">
                  <p class="py-1 my-0 active-form">Warehouse Information</p>
                </div>
              </div>
            </div>
            <!-- content section -->
            <div class="py-2">
              <div ref="formContainer" v-if="tabStep === 1">
                <div class="row ">
                  <div class="col-12 col-md-12">
                    <div class="form-group">
                      <label>Address</label>
                      <vue-google-autocomplete :enable-geolocation="true" ref="address" :country="['au']" id="map"
                                               classname="form-control" placeholder="Please type full address."
                                               v-on:placechanged="googleMapApiResponseData"/>
                    </div>
                  </div>
                </div>
                <div v-if="hasAddressData">
                  <p class="text-danger">Please modify the following address fields, if there anything wrong.</p>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label>Street Address</label>
                        <input v-model="postAddressData.street" type="text" class="form-control"
                               placeholder="Enter street address"/>
                        <div class="text-danger" v-if="errors.address.street">{{ errors.address.street }}</div>
                      </div>
                    </div>
                    <div class="col-12 col-lg-3 col-md-6 ">
                      <div class="form-group">
                        <label>Suburbs/ Territory</label>
                        <input v-model="postAddressData.suburb" type="text" class="form-control"
                               placeholder="Enter suburbs/ territory"/>
                        <div class="text-danger" v-if="errors.address.suburb">{{ errors.address.suburb }}</div>
                      </div>
                    </div>
                    <div class="col-12 col-lg-3 col-md-6">
                      <div class="form-group">
                        <label>State</label>
                        <VueMultiselect v-model="selectedState" class="" :options="state" :close-on-select="true"
                                        placeholder="Select state" label="name" track-by="value" :show-labels="false"/>
                        <div class="text-danger" v-if="errors.address.state">{{ errors.address.state }}</div>
                      </div>
                    </div>
                    <div class="col-12 col-lg-3 col-md-6">
                      <div class="form-group">
                        <label>Post Code</label>
                        <input v-model="postAddressData.post_code" type="text" class="form-control"
                               placeholder="Enter code"/>
                        <div class="text-danger" v-if="errors.address.postCode">{{ errors.address.postCode }}</div>
                      </div>
                    </div>
                    <div class="col-12 col-lg-3 col-md-6">
                      <div class="form-group">
                        <label>Country</label>
                        <input v-model="postAddressData.country" type="text" class="form-control" readonly/>
                        <div class="text-danger" v-if="errors.address.country">{{ errors.address.country }}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="d-flex justify-content-end">
                      <button type="button" @click="createSingleAddress" class="btn btn-primary update-todo px-4">Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="tabStep === 2">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label>Warehouse name *</label>
                      <input v-model="postWarehouseData.name" type="text" class="form-control text-capitalize"
                             placeholder="Enter warehouse name">
                      <div class="text-danger" v-if="errors.warehouse.name">{{ errors.warehouse.name }}</div>
                    </div>
                  </div>

                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label>Phone Number</label>
                      <input v-model="postWarehouseData.contact_number" type="text" class="form-control "
                             placeholder="ie. 0412345678">
                      <div class="text-danger"></div>
                    </div>

                  </div>
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label>GPS Location</label>
                      <input v-model="postWarehouseData.gps_location" type="text" class="form-control" placeholder="">
                      <div class="text-danger"></div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label>Managed By</label>
                      <VueMultiselect :allow-empty="false" v-model="selectedStoreManager" class="text-capitalize" :options="storeManager"
                                      :close-on-select="true"
                                      placeholder="Select state" label="name" track-by="value" :show-labels="false"/>
                      <div class="text-danger"></div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 ">
                    <div class="d-block d-sm-flex justify-content-end">
                      <div class="pb-1 pb-sm-0">
                        <button type="button" @click="tabPrev" class="btn btn-primary update-todo px-4">Prev</button>
                      </div>
                      <button type="button" @click="createSingleWarehouse"
                              class="ml-0 ml-sm-2 btn btn-primary update-todo px-4">Create Warehouse
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </AppLayout>
</template>

<script>
import AppLayout from '@/layouts/backEnd/AppLayout.vue';

import {mapActions, mapGetters} from "vuex";

import VueGoogleAutocomplete from 'vue-google-autocomplete';
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Authorization from "@/components/backEnd/mixins/Authorization";
import Loader from "@/components/backEnd/mixins/Loader";
export default {
  components: {
    AppLayout,
    VueGoogleAutocomplete

  },
  name: "CreateWarehouse",
  mixins: [ShowToastMessage, Authorization, Loader],
  data() {
    return {
      tabStep: 1,
      postAddressData: {
        street: '',
        suburb: '',
        state: '',
        post_code: '',
        country: '',
      },

      postWarehouseData: {
        managed_by: '',
        address_id: '',
        name: '',
        contact_number: '',
        gps_location: '',
        status: 1,
      },

      selectedState: {
        value: '',
        name: 'None'
      },

      selectedRole: {
        value: '',
        name: 'None'
      },
      selectedStoreManager: {
        value: '',
        name: 'None',
      },

      status: [
        {
          value: 0,
          name: 'Inactive'
        },
        {
          value: 1,
          name: 'Active'
        },
      ],
      selectedStatus: {
        value: 1,
        name: 'Active'
      },


      errors: {
        address: {
          street: '',
          suburb: '',
          state: '',
          postCode: '',
          country: '',
        },
        warehouse: {
          managed_by: '',
          name: ''
        }
      },

      getSettingsParams: {
        type: ['default'],
        key: ['default_state', 'customer_type'],
      },

      getTeamLeadsParams: {
        status: 1,
        with_relation: ['user'],

      },
      getStoreManagersParam: {
        status: 1,
        with_relation: ['user'],
      },
    };
  },

  watch: {
    selectedState() {
      this.postAddressData.state = this.selectedState.value;
    },
    selectedStoreManager() {
      this.postWarehouseData.managed_by = this.selectedStoreManager.value;
    }


  },
  computed: {
    ...mapGetters({
      previousRoute: 'previousRoute',

    }),
    hasAddressData() {
      return !!(this.postAddressData.street || this.postAddressData.suburb || this.postAddressData.state || this.postAddressData.post_code);
    },
    state() {
      return this.$store.getters['appSettings/settingDefaultState'].value
    },
    storeManager() {
      let storeUsers = this.$store.getters['appStoreManagers/storeManagers'].map((storeUser) => {
        let id = storeUser.user.id;
        let name = `${storeUser.user.first_name}  ${storeUser.user.last_name}`;
        return {value: id, name: name};
      });
      return [{value: '', name: 'None'}, ...storeUsers];
    },
    redirectRouteName() {
      return this.can('warehouse-view-any') ? 'appWarehouseList' : 'appWarehouseCreate';
    },

  },
  methods: {
    ...mapActions({
      getSettings: 'appSettings/getSettings',
      postAddress: 'appAddresses/postAddress',
      postWarehouse: 'appWarehouses/postWarehouse',
      getStoreManagers: 'appStoreManagers/getStoreManagers',
    }),

    async getSettingList(settingParams) {
      await this.getSettings(settingParams);
    },
    async tabPrev() {
      this.tabStep--;
    },

    async tabNext() {
      this.tabStep++;
    },

    async tabJump(tabToJump) {
      this.tabStep = tabToJump;
    },

    googleMapApiResponseData: function (googleMapApiResponseData) {
      let loader = this.$loading.show({
        // Optional parameters
        container: this.$refs.formContainer,
        canCancel: false,
        loader: 'spinner',
        height: 80,
        width: 80,
        color: '#5a8dee',
        onCancel: this.onCancel,
      });

      let streetNumber = googleMapApiResponseData.street_number !== undefined ? googleMapApiResponseData.street_number : '';
      let streetName = googleMapApiResponseData.route !== undefined ? googleMapApiResponseData.route : '';
      let suburbs = googleMapApiResponseData.locality !== undefined ? googleMapApiResponseData.locality : '';
      let state = googleMapApiResponseData.administrative_area_level_1 !== undefined ? googleMapApiResponseData.administrative_area_level_1 : '';
      let postCode = googleMapApiResponseData.postal_code !== undefined ? googleMapApiResponseData.postal_code : '';
      let country = googleMapApiResponseData.country !== undefined ? googleMapApiResponseData.country : '';

      this.postAddressData.street = streetNumber ? `${streetNumber} ${streetName}` : streetName;
      this.postAddressData.suburb = suburbs;
      this.postAddressData.state = state.toLowerCase();
      this.postAddressData.post_code = postCode;
      this.postAddressData.country = country;

      if (state) {
        let addressStateIndex = this.state.findIndex(item => item.value === state.toLowerCase());
        if (addressStateIndex !== -1) {
          let addressState = this.state[addressStateIndex];
          this.selectedState.value = addressState.value;
          this.selectedState.name = addressState.name;
        }
      }

      setTimeout(() => {
        loader.hide();
      }, 600);
    },

    async createSingleAddress() {
      await this.postAddress(this.postAddressData).then((response) => {
        if (response.status === 200 || response.status === 201) {
          let addressId = this.$store.getters['appAddresses/address'].id;
          console.log(addressId);
          this.postWarehouseData.address_id = addressId;
          this.tabNext();
        }

        this.errors.address.street = response.errors && response.errors.street ? response.errors.street[0] : '';
        this.errors.address.suburb = response.errors && response.errors.suburb ? response.errors.suburb[0] : '';
        this.errors.address.state = response.errors && response.errors.state ? response.errors.state[0] : '';
        this.errors.address.postCode = response.errors && response.errors.post_code ? response.errors.post_code[0] : '';
        this.errors.address.country = response.errors && response.errors.country ? response.errors.country[0] : '';

        if (response.message) {
          this.showToastMessage(response);
        }
      });
    },
    async createSingleWarehouse() {
      this.loader(true);
      await this.postWarehouse(this.postWarehouseData).then(async (response) => {
        if (response.status === 201) {
           this.loader(false)
          const toastObj = {message: 'Warehouse created successful.', type: 'success'};
          this.showToastMessage(toastObj);

          await this.$router.push({
            name: this.redirectRouteName,
          });
        }
        this.errors.warehouse.name = response.errors && response.errors.name ? response.errors.name[0] : '';
        if (response.message) {
          this.showToastMessage(response);
        }
        if (this.errors.warehouse.name) {
          await this.tabJump(2);
        }
        this.loader(false);
      });

    },
  },
 async mounted() {
  this.loader(true);
   await this.getSettingList(this.getSettingsParams);
   await this.getStoreManagers(this.getStoreManagersParam);
   this.loader(false);
  },
}
</script>

<style scoped>
.active-form {
  background-color: #5A8DEE;
  color: white;
  font-weight: bold;
}

/*.inactive-form {
  background-color: #F0EFF4;
  color: #414141;
  font-weight: 500;
}*/

@media screen and (max-width: 995px), screen and (max-height: 700px) {
  .active-form {
    background-color: #5A8DEE;
    color: white;
    font-weight: bold;
  }
}
</style>
